/*
 * @Author: mengxiangfa
 * @Date: 2021-04-20 15:37:24
 * @LastEditors: mengxiangfa
 * @LastEditTime: 2021-06-07 15:42:38
 * @Description: file content
 * @FilePath: \DDDgClient\src\store\index.js
 */
import Vuex from 'vuex'
import Vue from 'vue'
import Cookies from 'js-cookie'
import { login } from '../request/api'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    token: Cookies.get('token') || '',
  },
  getters: {},

  mutations: {
    SET_TOKEN(state, token) {
      state.token = token
      // 持久化保存 token
      // 方便下次获取
      let num = 24 //失效时间是几小时
      let time = new Date(new Date().getTime() + num * 60 * 60 * 1000)
      Cookies.set('token', token, {
        expires: time,
      })
    },

    logout(state) {
      state.token = ''
      Cookies.remove('token')
    },
  },

  actions: {
    login({ commit }, payload) {
      const { username, password } = payload
      // 异步的
      // commit('login', {
      //   token: `${username}${password}`,
      // })
      return new Promise((resolve, reject) => {
        login({ username: username.trim(), password: password })
          .then(response => {
            const { data } = response
            commit('SET_TOKEN', data.token)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
})

export default store
