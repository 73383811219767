/*
 * @Author: mengxiangfa
 * @Date: 2021-04-20 13:43:26
 * @LastEditors: mengxiangfa
 * @LastEditTime: 2021-07-01 11:44:29
 * @Description: file content
 * @FilePath: \DDDgClient\src\main.js
 */
import Vue from "vue"
import "./style/index.scss"
import App from "./App.vue"
import store from "./store"
import router from "./router"
import "amfe-flexible"
import vantComponents from "./utils/components" //引入vant
import dataURLtoFile from "@/utils/dataURLtoFile.js"
import formValidate from "@/utils/formValidation.js"
import getWXConfig from "@/utils/wxConfig.js"

Vue.config.productionTip = false
Vue.use(vantComponents)
Vue.prototype.$dataURLtoFile = dataURLtoFile
Vue.prototype.$formValidate = formValidate
Vue.prototype.$getWXConfig = getWXConfig

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app")
