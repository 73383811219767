/*
 * @Author: mengxiangfa
 * @Date: 2021-04-20 15:11:11
 * @LastEditors: mengxiangfa
 * @LastEditTime: 2021-07-16 17:52:19
 * @Description: file content
 * @FilePath: \DDDgClient\src\request\api.js
 */
// eslint-disable-next-line no-unused-vars
import { get, post, postform } from "./http"
//登录
export const login = p => post("/login", p)

//获取微信js-sdk签名
export const getWXSignature = p => post("/getWeiXinSignature", p)

//获取用户加入的项目列表
export const getProjects = p => post("/getProjectList", p)

//获取位置关系树
export const getLocationTree = p => post("/getLocationTree", p)

//获取项目的全部mac地址
export const getMacs = p => post("/getMacList", p)

//位置上传
export const updateLocation = p => postform("/updateLocation", p)

//获取模块信息
export const getModuleDetail = p => post("/getModuleDetail", p)

//模块更换
export const updateModule = p => postform("/updateModule", p)

//模块更换记录
export const getModuleChange = p => post("/getModuleChangeList", p)

//获取开关详情
export const getBreakerDetail = p => post("/getBreakerDetail", p)

//开关更换
export const updateBreaker = p => post("/updateBreaker", p)

//获取开关更换记录
export const getBreakerChangeList = p => post("/getBreakerChangeList", p)
