/*
 * @Author: mengxiangfa
 * @Date: 2021-05-18 11:36:03
 * @LastEditors: mengxiangfa
 * @LastEditTime: 2021-06-21 09:46:50
 * @Description: file content
 * @FilePath: \DDDgClient\src\utils\components.js
 */
import {
  PullRefresh,
  Checkbox,
  CheckboxGroup,
  Overlay,
  Popover,
  List,
  DropdownMenu,
  DropdownItem,
  Calendar,
  Popup,
  Field,
  SwipeCell,
  Loading,
  Switch,
  Picker,
  Col,
  Row,
  Button,
  Form,
  Icon,
  ActionSheet,
  Uploader,
  Cascader,
} from 'vant'

const components = {
  PullRefresh,
  Checkbox,
  CheckboxGroup,
  Overlay,
  Popover,
  List,
  DropdownMenu,
  DropdownItem,
  Calendar,
  Popup,
  Field,
  SwipeCell,
  Loading,
  Switch,
  Picker,
  Col,
  Row,
  Button,
  Form,
  Icon,
  ActionSheet,
  Uploader,
  Cascader,
}
function install(Vue) {
  Object.keys(components).forEach(key => Vue.use(components[key]))
}
export default { install }
