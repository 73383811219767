/*
 * @Author: mengxiangfa
 * @Date: 2021-05-21 14:35:50
 * @LastEditors: mengxiangfa
 * @LastEditTime: 2021-11-15 11:53:28
 * @Description:base64转file
 * @FilePath: \DDDgClient\src\utils\dataURLtoFile.js
 */

const custom = function(data, fileName) {
  return dataURLtoFile(data, fileName)
}
function dataURLtoFile(data, filename) {
  let imageBase64 = ""

  if (data.indexOf("data:image") == 0) {
    //苹果的直接赋值，默认生成'data:image/jpeg;base64,'的头部拼接
    imageBase64 = data
  } else {
    //此处是安卓中的唯一得坑！在拼接前需要对localData进行换行符的全局替换
    //此时一个正常的base64图片路径就完美生成赋值到img的src中了
    imageBase64 = "data:image/jpeg;base64," + data.replace(/\n/g, "")
  }
  let arr = imageBase64.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}
export default custom
