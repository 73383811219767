/*
 * @Author: mengxiangfa
 * @Date: 2021-04-20 13:43:26
 * @LastEditors: mengxiangfa
 * @LastEditTime: 2021-10-25 13:31:22
 * @Description: file content
 * @FilePath: \DDDgClient\src\router\index.js
 */
import Vue from "vue"
import VueRouter from "vue-router"
import store from "../store"

Vue.use(VueRouter)
const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/home.vue"),
    meta: {
      title: "首页",
      requireAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/login.vue"),
    meta: {
      title: "登录",
    },
  },
  {
    path: "/updateLocation",
    name: "updateLocation",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/location/index.vue"),
    meta: {
      title: "位置上传",
      requireAuth: true,
    },
  },
  {
    path: "/changeModule",
    name: "changeModule",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/module/index.vue"),
    meta: {
      title: "模块更换",
      requireAuth: true,
    },
  },
  {
    path: "/moduleRecord",
    name: "moduleRecord",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/module/record.vue"),
    meta: {
      title: "模块更换记录",
      requireAuth: true,
    },
  },
  {
    path: "/changeSwitch",
    name: "changeSwitch",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/switch/index.vue"),
    meta: {
      title: "开关更换",
      requireAuth: true,
    },
  },
  {
    path: "/switchRecord",
    name: "switchRecord",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/switch/record.vue"),
    meta: {
      title: "开关更换记录",
      requireAuth: true,
    },
  },
  //测试
  {
    path: "/test",
    name: "test",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/test/fx.vue"),
    meta: {
      title: "测试",
    },
  },
]
const router = new VueRouter({
  mode: "hash", //hash
  // base: process.env.BASE_URL,
  base: "/", //site/wxtest/
  routes,
})
// 导航守卫
// 提前检测有没有 token
router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    // 看看 store 里面有没有 token
    if (store.state.token) {
      next()
    } else {
      next({
        name: "login",
      })
    }
  } else {
    next()
  }
})

export default router
