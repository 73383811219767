/*
 * @Author: mengxiangfa
 * @Date: 2021-06-08 13:31:49
 * @LastEditors: mengxiangfa
 * @LastEditTime: 2021-06-08 13:48:03
 * @Description: 表单验证
 * @FilePath: \DDDgClient\src\utils\formValidation.js
 */
import { Toast } from 'vant'
const custom = function(params) {
  return paramsValidate(params)
}
function paramsValidate(params) {
  let flag = true

  for (var key in params) {
    if (params[key] != '0' && !params[key]) {
      Toast('请完善数据!')
      return false // 终止程序
    }
  }

  return flag
}
export default custom
