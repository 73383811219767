/*
 * @Author: mengxiangfa
 * @Date: 2021-06-09 14:25:00
 * @LastEditors: mengxiangfa
 * @LastEditTime: 2021-10-25 14:41:02
 * @Description: 配置微信config
 * @FilePath: \DDDgClient\src\utils\wxConfig.js
 */
import wx from "weixin-js-sdk" //引入微信js-sdk
import { getWXSignature } from "../request/api"
const custom = function(url, jsApiList) {
  return getWXConfig(url, jsApiList)
}
function getWXConfig(url, jsApiList) {
  getWXSignature({ url })
    .then(res => {
      let { appId, timestamp, noncestr, signature } = res.data
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: appId, // 必填，公众号的唯一标识
        timestamp: timestamp, // 必填，生成签名的时间戳
        nonceStr: noncestr, // 必填，生成签名的随机串
        signature: signature, // 必填，签名
        jsApiList: jsApiList, // 必填，需要使用的JS接口列表
      })
      wx.error(function(res) {
        console.log("wxerr", res)
      })
    })
    .catch(err => {
      console.log(err)
    })
}
export default custom
