/*
 * @Author: mengxiangfa
 * @Date: 2021-04-20 15:11:11
 * @LastEditors: mengxiangfa
 * @LastEditTime: 2021-11-05 15:21:47
 * @Description: file content
 * @FilePath: \DDDgClient\src\request\http.js
 */
import axios from "axios"
import Cookies from "js-cookie"
import { Toast } from "vant"

// 环境的切换
// axios.defaults.baseURL = '/api' // 开发环境
// axios.defaults.baseURL = "http://47.103.61.198:5018/api" // 测试环境
axios.defaults.baseURL = "https://www.ddingsafehome.com.cn/dg/api" //http://192.168.9.163:5000/api
// 请求超时时间
axios.defaults.timeout = 60000
// 请求头
axios.defaults.headers.post["Content-Type"] = "application/json; charset=utf-8"

let instance = axios.create({
  headers: { "content-type": "multipart/form-data" },
})
// 请求拦截器
axios.interceptors.request.use(
  config => {
    // 获取token
    const token = Cookies.get("token")
    if (token) {
      config.headers["token"] = token
    }
    return config
  },
  error => {
    return Promise.error(error)
  }
)

//响应拦截器
axios.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code !== 200 || res.isError) {
      Toast(res.message)
    } else {
      return response
    }
    // if (response.status === 200) {
    //   if (response.data.code === 200 || !response.data.isError) {
    //     return Promise.resolve(response)
    //   } else {
    //     Toast(response.data.message)
    //     return Promise.reject(response)
    //   }
    // } else {
    //   Toast(response.data.message)
    //   return Promise.reject(response)
    // }
  },
  error => {
    // if (error.response.data.code) {
    //   switch (error.response.data.code) {
    //     case 400:
    //       Toast({
    //         message: "请求参数有误!",
    //         duration: 1500,
    //         forbidClick: true,
    //       })
    //       break
    //     case 404:
    //       Toast({
    //         message: "网络请求不存在!",
    //         duration: 1500,
    //         forbidClick: true,
    //       })
    //       break
    //     case 500:
    //       Toast({
    //         message: "服务器错误!",
    //         duration: 1500,
    //         forbidClick: true,
    //       })
    //       break
    //     default:
    //       Toast({
    //         message: error.response.data.message,
    //         duration: 1500,
    //         forbidClick: true,
    //       })
    //   }
    Toast({
      message: error.response.data.message,
      duration: 1500,
      forbidClick: true,
    })
    return Promise.reject(error.data)
    // }
  }
)
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params, config = { add: "" }) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        url,
        {
          params: params,
        },
        config
      )
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params, config = { isUpload: false }) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params, config)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

/**
 * 封装post请求 FormData方式
 * @param url
 * @param data
 * @returns {Promise}
 */

export function postform(url, data = {}) {
  return new Promise((resolve, reject) => {
    instance.post(url, data).then(
      response => {
        resolve(response.data)
      },
      err => {
        reject(err)
      }
    )
  })
}
