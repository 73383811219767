<!--
 * @Author: mengxiangfa
 * @Date: 2021-04-20 13:43:26
 * @LastEditors: mengxiangfa
 * @LastEditTime: 2021-06-08 14:10:52
 * @Description: file content
 * @FilePath: \DDDgClient\src\App.vue
-->
<template>
  <div id="app">
    <router-view v-if="alive" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      alive: true,
    }
  },
  provide() {
    return {
      reload: this.reload,
    }
  },
  methods: {
    reload() {
      this.alive = false
      this.$nextTick(() => {
        this.alive = true
      })
    },
  },
}
</script>
